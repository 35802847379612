<template>
  <v-dialog v-if="dialog" v-model="dialog" width="800px" scrollable>
    <v-card>
      <v-card-title>
        <card-title-with-back-button
          :title="`Checar par: ${checkPar.name}`"
          @back="close"
        />
      </v-card-title>
      <v-card-text ref="modalContent">
        <tracking-impaction-point-input-sides
          v-if="!loading"
          ref="trackingComponent"
          v-model="pointsSides"
          :tracking-point="trackingPoint"
          :impaction-point="impactionPoint"
          class="grey mb-3"
        />
        <property-value property="Tipo do par" :value="checkPar.type.name" />

        <select-par-properties
          v-model="diagnosePar.symptoms"
          label="Sintomas"
          :items="checkPar.symptoms"
          class="mb-3"
        />
        <select-par-properties
          v-model="diagnosePar.pathogens"
          label="Patógenos"
          :items="checkPar.pathogens"
        />
        <v-switch
          v-model="diagnosePar.hidePatient"
          label="Não mostrar ao paciente no diagnóstico"
        />
        <v-switch
          v-model="diagnosePar.patientImpactAtHome"
          label="Recomendar ao paciente impactar em casa"
        />
        <recommendations
          class="my-3"
          :recommendations="checkPar.recommendations"
        />
        <div class="mt-4">
          <span class="font-weight-bold">Comentários: </span>

          <div class="mt-4">
            <v-textarea
              :value="comments.therapistComments"
              outlined
              label="Comentários exclusivo para terapeutas (não será mostrado ao paciente)"
              rows="3"
              @input="(inputValue) => setValue(inputValue, 'therapistComments')"
              @blur="input"
            />
          </div>
          <div>
            <v-textarea
              :value="comments.patientComments"
              outlined
              label="Comentários para o paciente"
              rows="3"
              @input="(inputValue) => setValue(inputValue, 'patientComments')"
              @blur="input"
            />
          </div>
        </div>

        <check-pares-buttons
          :value="checkPar.checkPares"
          class="mt-5"
          :can-open="isParAddedToDiagnose"
          @click="clickAnyParToCheck"
        />
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-column full-width">
          <add-to-diagnose-button
            :is-added-to-diagnose="isParAddedToDiagnose"
            @click="addToDiagnose"
          />
          <v-btn
            v-if="existsParToBack"
            block
            large
            class="mt-3"
            outlined
            color="secondary"
            @click="backCheckPar"
          >
            Voltar para Par anterior
          </v-btn>

          <v-btn
            v-if="!existsParToBack"
            block
            large
            class="mt-3"
            outlined
            color="black"
            @click="closeDialog"
          >
            <v-icon class="mr-1">arrow_back</v-icon>
            Voltar ao Par Principal
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    "check-pares-buttons": () => import("../components/CheckParesButtons"),
  },
  data: () => ({
    diagnosePar: {},
    pointsSides: {},
    comments: {},
    isParAddedToDiagnose: false,
    loading: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.pares.checkPar;
      },
      set(value) {
        this.$store.commit("dialogs/setParesCheckPar", value);
      },
    },
    trackingPoint() {
      return this.checkPar.points?.trackingPoint;
    },
    impactionPoint() {
      return this.checkPar.points?.impactionPoint;
    },
    ...mapState({
      checkPar: (state) => state.pares.checkPar,
    }),
    ...mapGetters({
      existsParToBack: "pares/existsParToBack",
    }),
  },

  async beforeUpdate() {
    this.isParAddedToDiagnose = await this.$store.dispatch(
      "diagnosis/verifyIfExistsParInDiagnosis",
      this.checkPar.id
    );
  },

  methods: {
    close() {
      this.$store.commit("pares/clearCheckParesHistory");
      this.dialog = false;
    },
    setValue(value, field) {
      this.comments[field] = value;
    },
    input() {
      // do nothing
    },
    async addToDiagnose() {
      try {
        await this.$store.dispatch(
          "diagnosis/validateIfExistsParInDiagnosis",
          this.checkPar.id
        );
        await this.$store.dispatch("diagnosis/addParToDiagnose", {
          ...this.diagnosePar,
          par: this.checkPar,
          trackingPointSide: this.pointsSides.trackingPointSide,
          impactionPointSide: this.pointsSides.impactionPointSide,
          therapistComments: this.comments.therapistComments,
          patientComments: this.comments.patientComments,
        });
        await this.$store.dispatch(
          "points/changeFieldAddedToDiagnosis",
          {
            parId: this.checkPar.id,
            isAdded: true,
          },
          { root: true }
        );
        this.$toasted.global.success({
          message: "Par adicionado ao diagnóstico.",
        });
        this.clearDiagnosePar();
        this.isParAddedToDiagnose = true;
      } catch (error) {
        this.$toasted.global.error({ message: error.message });
      }
    },
    async clickAnyParToCheck() {
      this.loading = true;
      if (!this.isParAddedToDiagnose) {
        this.$warningAlert({
          message:
            "Antes de checar os pares, adicione o par atual ao diagnóstico.",
        });
      } else {
        this.comments = {
          therapistComments: "",
          patientComments: "",
        };
        this.pointsSides = {};
        this.$refs.trackingComponent.clear();
        this.$refs.modalContent.scrollTop = 0;
      }
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    clearDiagnosePar() {
      this.diagnosePar = {};
      this.pointsSides = {};
      this.comments = {
        therapistComments: "",
        patientComments: "",
      };
    },
    backCheckPar() {
      this.comments = {};
      this.pointsSides = {};
      this.$store.dispatch("pares/backCheckParHistory");
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.full-width > * {
  width: 100%;
}
</style>
